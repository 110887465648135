import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style/main.scss'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import setLoginState from '@/assets/js/loginstate'
import i18n from '@/assets/lang/i18n'

router.beforeEach((to, from, next) => {
  let localstorage = window.localStorage.getItem('userToken')
  if (localstorage) {
    let temp = JSON.parse(localstorage)
    setLoginState(store, temp)
  }
  next()
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(i18n)
app.mount('#app')
