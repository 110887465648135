import { createStore } from 'vuex'

export default createStore({
  state: {
    address: '',
    extension: '',
  },
  getters: {},
  mutations: {
    setAddress(state, address) {
      state.address = address
    },
    setExtension(state, extension) {
      state.extension = extension
    },
  },
  actions: {},
  modules: {},
})
