export default {
  message: {
    Wallet_address: '钱包地址',
    Log_out: '登出',
    Authorized_contract: '授权合约',
    Authorized_token: '授权代币',
    Authorized_limit: '授权额度',
    Change_quota: '额度修复',
    unlimited: '无限',
    confirm_title: '授权额度修改',
    confirm_content: '确认将当前合约授权额度修改为0？',
    confirm: '确认',
    cancle: '取消',
    revoke: '撤销',
    token: '代币',
    address: '地址',
    limit: '限制',
    approval: '授权',
    action: '操作',
  },
}
