const setLoginState = (store, para) => {
  if (!para) {
    window.localStorage.removeItem('userToken')
    store.commit('setAddress', null)
    store.commit('setExtension', null)
  } else {
    window.localStorage.setItem('userToken', JSON.stringify(para))
    store.commit('setAddress', para.address)
    store.commit('setExtension', para.extension)
  }
}

export default setLoginState
